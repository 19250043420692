<template>
    <div class="common-content">
        <DiagramBar
        :btnLoading="tableLoading"
        @onQuery="onQuery"
        @update:cloud="updateCloud" 
        @update:region="updateRegion" 
        @update:granularity="updateGranularity"
        @update:dimension="updateDimension"
        @update:date="updateDate"
        @update:refDate="updateRefDate"
        @showTable="isCollectionTableShow = true"></DiagramBar>
        <n-spin :show="chartLoading">
            <div id="container" :style="{ height: paperHeight + 'px', overflow: 'auto'}">
                <div
                    id="konva_app"
                    :style="{ height: designHeight * 0.75 + 'px', width: designWidth * 0.75 + 'px'}"
                    class="paper"
                ></div>
            </div>
            <template #description> 数据读取中, 请稍后... </template>
        </n-spin>
        <CollectionDataView v-model:show="isViewCollectionShow" :collection="collectionData" :queryObj="queryObj">
        </CollectionDataView>
        <!-- <DiagramTable v-model:show="isCollectionTableShow" 
        :tableData="tableData"
        :loading="tableLoading"
        :root="root"
        @update:show="isCollectionTableShow = false"
        @openCollection="openCollection">
        </DiagramTable> -->
        <DiagramTableV2 v-model:show="isCollectionTableShow" 
        :tableData="tableData"
        :root="root"
        type="online"
        :granularity="granularity"
        :start="start"
        :end="end"
        :dimensionList="dimensionList"
        :loadRef="startRef !== '' && endRef !== ''"
        :startRef="startRef"
        :endRef="endRef"
        :curData="curData"
        @update:show="isCollectionTableShow = false"
        @openCollection="openCollection">
        </DiagramTableV2>
    </div>
</template>
<style lang="less" scoped>
@import '../../../../common/common.less';
.paper {
    .standard-border;
    .standard-shadow;
    background-color: #fff;
}
</style>
<script setup>
import { ref, onMounted, nextTick, watch, computed } from 'vue';
import { useRoute } from 'vue-router';
import DiagramBar from '@/views/Business/Analysis/components/DiagramBar.vue';
import DiagramTable from '@/views/Business/Analysis/components/DiagramTable.vue';
import DiagramTableV2 from '@/views/Business/Analysis/components/DiagramTableV2.vue';

import dayjs from 'dayjs';
import { NSpin } from 'naive-ui';
import KonvaApp from './Konva/Entry';
import collection from './Collection';
import tableCollection from './TableColletion';

// import collection from './CollectionTest';
// import tableCollection from './TableCollectionTest';

import { CollectionAPI } from '@/common/API';
import { computePreDate, computeYoyDate, convertWithoutRecursion, getDimensionListVal } from '@/views/Business/Analysis/tools/tool.js';
import CollectionDataView from '@/views/CostLens/components/CollectionDataView';

const route = useRoute();

let isCollectionTableShow = ref(false);

let isViewCollectionShow = ref(false);
let collectionData = ref({
    id: null,
    name: '',
    admin: '',
    feFilterState: null,
    tags: [],
    teamgroups: [],
});
let queryObj = ref({
    granularity: null,
    start_time: null,
    end_time: null,
    dateRes: null,
})
let dimensionList = ref(['all']);

let granularity = ref(route.query.granularity || 'day');
let start = ref(route.query.start || dayjs().subtract(8, 'day').format('YYYY-MM-DD'));
let end = ref(route.query.end || dayjs().subtract(2, 'day').format('YYYY-MM-DD'));
let dateRes = ref(route.query.start ? { range: [(new Date(route.query.start)).getTime(), (new Date(route.query.end)).getTime()] } : {
    type: 'last7',
    range: [dayjs().subtract(8, 'day').valueOf(), dayjs().subtract(2, 'day').valueOf()],
});

let dateResRef = ref(route.query.startRef ? { range: [(new Date(route.query.startRef)).getTime(), (new Date(route.query.endRef)).getTime()] } :{});
let startRef = ref(route.query.startRef || '');
let endRef = ref(route.query.endRef || '');

let chartData = ref({});
let chartLoading = ref(false);

const designWidth = 2600;
const designHeight = 1350;

let dom = null;
let app = null;

let paperHeight = ref(99);
let item = ref('');
let cloud = ref(route.query.cloud || '');
let region = ref(route.query.region || '');

let tableData = ref([]);
let tableLoading = ref(true);
let root = ref([]);
let curData = ref({});
let yoyData = ref({});
let preData = ref({});
let refData = ref({});

let curLoadingComplete = ref(false);
let yoyLoadingComplete = ref(false);
let preLoadingComplete = ref(false);
let refLoadingComplete = ref(true);

function onQuery() {
    loadMutiple()
    const yoyDate = computeYoyDate(granularity.value, start.value, end.value);
    const preDate = computePreDate(granularity.value, start.value, end.value);
    load(yoyDate[0], yoyDate[1], 'yoy')
    load(preDate[0], preDate[1], 'pre')

    if (startRef.value !== '' && endRef.value !== '') {
        load(startRef.value, endRef.value, 'ref')
    }
};

function updateCloud(value) {
    cloud.value = value
};
function updateRegion(value) {
    region.value = value
};
function updateDimension(value) {
    if (value.cloud === 'all' && value.region === 'all') {
        dimensionList.value = ['all']
    } else if (value.cloud !== 'all' && value.region === 'all') {
        dimensionList.value = ['cloud']
        item.value = value.cloud
    } else if (value.cloud === 'all' && value.region !== 'all') {
        dimensionList.value = ['region']
        item.value = value.region
    } else {
        dimensionList.value = ['cloud', 'region']
    }
};

function updateGranularity(val) {
    granularity.value = val;
};

function updateDate(val) {
    start.value = val.start_time;
    end.value = val.end_time;
    dateRes.value = val.dateRes;
};

function updateRefDate(val) {
    refData.value = {}
    startRef.value = val.start_time;
    endRef.value = val.end_time;
    dateResRef.value = val.dateResRef;
};

function load(startVal, endVal, tag) {
    switch (tag) {
        case 'yoy':
            yoyData.value = {}
            yoyLoadingComplete.value = false;
            break;
        case 'pre':
            preData.value = {}
            preLoadingComplete.value = false;
            break;
        case 'ref':
            refData.value = {}
            refLoadingComplete.value = false;
            break;
        default:
            break;
    }    
    
    tableLoading.value = true
    for (const cid in root.value) {
        CollectionAPI.getCostDataByCollectionIdM(
            // collection[cid],
            root.value[cid],
            startVal,
            endVal,
            granularity.value,
            dimensionList.value,
        )
            .then(res => {
                let end_date = new Date('2000-01-01 00:00:00')
                let end_value = 0.0
                let avg = 0.0;
                for (const key in res.data) {
                    if (dimensionList.value.length === 1) {
                        if (dimensionList.value[0] === 'all') {
                            if (key === 'MV') {
                                let sum = 0.0;
                                const len = Object.keys(res.data[key]).length;
                                for (const d in res.data[key]) {
                                    sum += res.data[key][d];
                                    if (end_date < new Date(d)) {
                                        end_date = new Date(d)
                                    }
                                }
                                avg = (sum / len).toFixed(2);
                                end_value = res.data[key][dayjs(end_date).format('YYYY-MM-DD HH:mm:ss')]
                            }
                        } else {
                            if (key === item.value) {
                                let sum = 0.0;
                                const len = Object.keys(res.data[key]).length;
                                for (const d in res.data[key]) {
                                    sum += res.data[key][d];
                                    if (end_date < new Date(d)) {
                                        end_date = new Date(d)
                                    }
                                }
                                avg = (sum / len).toFixed(2);
                                end_value = res.data[key][dayjs(end_date).format('YYYY-MM-DD HH:mm:ss')]
                            }
                        }
                    } else {
                        const cloudregion = cloud.value+':'+region.value
                        if (key === cloudregion) {
                            let sum = 0.0;
                            const len = Object.keys(res.data[key]).length;
                            for (const d in res.data[key]) {
                                sum += res.data[key][d];
                                if (end_date < new Date(d)) {
                                    end_date = new Date(d)
                                }
                            }
                            avg = (sum / len).toFixed(2);
                            end_value = res.data[key][dayjs(end_date).format('YYYY-MM-DD HH:mm:ss')]
                        }
                    }
                }

                if (tag === 'current') {
                    curData.value[root.value[cid]] = {
                        id: root.value[cid],
                        value: end_value,
                        name: res.data.query.collection_name,
                    };
                } else if (tag === 'yoy') {
                    yoyData.value[root.value[cid]] = {
                        id: root.value[cid],
                        value: end_value,
                        name: res.data.query.collection_name,
                    };
                } else if (tag === 'pre') {
                    preData.value[root.value[cid]] = {
                        id: root.value[cid],
                        value: end_value,
                        name: res.data.query.collection_name,
                    };
                } else if (tag === 'ref') {
                    refData.value[root.value[cid]] = {
                        id: root.value[cid],
                        value: avg,
                        name: res.data.query.collection_name,
                    };
                }
            })
            .catch(err => {
                console.log(err);
            });
    }
};

function loadMutiple() {
    chartLoading.value = true;
    curData.value = {}
    curLoadingComplete.value = false
    tableLoading.value = true
    let completedRequests = 0;
    for (const cid in collection) {
        CollectionAPI.getCostDataByCollectionIdM(
            collection[cid],
            start.value,
            end.value,
            granularity.value,
            dimensionList.value,
        )
            .then(res => {
                let avg = 0.0;
                let end_date = new Date('2000-01-01 00:00:00')
                let end_value = 0.0
                for (const key in res.data) {
                    if (dimensionList.value.length === 1) {
                        if (dimensionList.value[0] === 'all') {
                            if (key === 'MV') {
                                let sum = 0.0;
                                const len = Object.keys(res.data[key]).length;
                                for (const d in res.data[key]) {
                                    sum += res.data[key][d];
                                    if (end_date < new Date(d)) {
                                        end_date = new Date(d)
                                    }
                                }
                                avg = (sum / len).toFixed(2);
                                end_value = res.data[key][dayjs(end_date).format('YYYY-MM-DD HH:mm:ss')]
                            }
                        } else {
                            if (key === item.value) {
                                let sum = 0.0;
                                const len = Object.keys(res.data[key]).length;
                                for (const d in res.data[key]) {
                                    sum += res.data[key][d];
                                    if (end_date < new Date(d)) {
                                        end_date = new Date(d)
                                    }
                                }
                                avg = (sum / len).toFixed(2);
                                end_value = res.data[key][dayjs(end_date).format('YYYY-MM-DD HH:mm:ss')]
                            }
                        }
                    } else {
                        const cloudregion = cloud.value+';'+region.value
                        if (key === cloudregion) {
                            let sum = 0.0;
                            const len = Object.keys(res.data[key]).length;
                            for (const d in res.data[key]) {
                                sum += res.data[key][d];
                                if (end_date < new Date(d)) {
                                    end_date = new Date(d)
                                }
                            }
                            avg = (sum / len).toFixed(2);
                            end_value = res.data[key][dayjs(end_date).format('YYYY-MM-DD HH:mm:ss')]
                        }
                    }
                }

                chartData.value[cid] = { value: avg };
                app.setData(chartData.value);

                curData.value[collection[cid]] = { 
                    id: collection[cid],
                    value: end_value,
                    value_avg : avg,
                    name: res.data.query.collection_name,
                    admin: res.data.query.collection_definition.admin,
                    feFilterState: res.data.query.collection_definition.feFilterState,
                    tags: res.data.query.collection_definition.tags,
                    teamgroups: res.data.query.collection_definition.teamgroups,
                };

                completedRequests++;
                if (completedRequests === Object.keys(collection).length) {
                    chartLoading.value = false;
                }
            })
            .catch(err => {
                console.log(err);
                chartLoading.value = false;
            });
    }
}

function initKonvaApp() {
    if (app) {
        return;
    }
    dom = document.querySelector('#container');
    paperHeight.value = (dom.clientWidth * designHeight) / designWidth;
    nextTick(() => {
        app = new KonvaApp('konva_app');
        // 当paper在小屏上渲染时，可能因高度不够产生滚动条，继而又影响了dom宽度，导致实际宽度和之前计算的不同，这是需要再计算一遍
        paperHeight.value = (dom.clientWidth * designHeight) / designWidth;
        app.setSize(designWidth * 0.75, designHeight * 0.75);
        app.setClickHandler((name) => {
            collectionData.value['id'] = collection[name];
            collectionData.value['name'] = curData.value[collection[name]].name;
            collectionData.value['admin'] = curData.value[collection[name]].admin;
            collectionData.value['feFilterState'] = curData.value[collection[name]].feFilterState;
            collectionData.value['tags'] = curData.value[collection[name]].tags;
            collectionData.value['teamgroups'] = curData.value[collection[name]].teamgroups;
            queryObj.value = {
                granularity: granularity.value,
                // start_time: start.value,
                // end_time: end.value,
                dateRes: dateRes.value,
            }
            isViewCollectionShow.value = true;
        });

        // test
        // const avg = 0.0
        // for (const cid in collection) {
        //     chartData.value[cid] = { value: avg };
        //     app.setData(chartData.value);
        // } 
    });
    window.addEventListener('resize', () => {
        paperHeight.value = (dom.clientWidth * designHeight) / designWidth;
        app.setSize(designWidth * 0.75, designHeight * 0.75);
    });
}

function openCollection(value) {
    collectionData.value = value
    queryObj.value = {
        granularity: granularity.value,
        dateRes: dateRes.value,
    }
    isViewCollectionShow.value = true
};

onMounted(() => {
    [dimensionList.value, item.value] = getDimensionListVal(dimensionList.value, item.value, route.query.cloud, route.query.region)
    root.value = Object.keys(tableCollection)
    initKonvaApp();
    loadMutiple();

    const yoyDate = computeYoyDate(granularity.value, start.value, end.value);
    const preDate = computePreDate(granularity.value, start.value, end.value);
    load(yoyDate[0], yoyDate[1], 'yoy')
    load(preDate[0], preDate[1], 'pre')

    if (startRef.value !== '' && endRef.value !== '') {
        load(startRef.value, endRef.value, 'ref')
    }
});

watch (() => curData.value,
    () => {
        if (Object.keys(curData.value).length === Object.keys(collection).length) {
            curLoadingComplete.value = true;
        }
    },
    { deep: true }
);

watch (() => yoyData.value,
    () => {
        if (Object.keys(yoyData.value).length === Object.keys(tableCollection).length) {
            yoyLoadingComplete.value = true;
        }
    },
    { deep: true }
);

watch (() => preData.value,
    () => {
        if (Object.keys(preData.value).length === Object.keys(tableCollection).length) {
            preLoadingComplete.value = true;
        }
    },
    { deep: true }
);

watch (() => refData.value,
    () => {
        if (Object.keys(refData.value).length === Object.keys(tableCollection).length) {
            refLoadingComplete.value = true;
        }
    },
    { deep: true }
);

let oriDataLoadingComplete = computed(
    () => curLoadingComplete.value && yoyLoadingComplete.value && preLoadingComplete.value && refLoadingComplete.value
);

watch(() => oriDataLoadingComplete.value,
    (newVal, oldVal) => {
        if (newVal) {
            tableLoading.value = true
            tableData.value.splice(0, tableData.value.length)

            // tableData.value = convert(tableCollection, curData.value, yoyData.value, preData.value, refData.value);

            // root.value = Object.keys(tableCollection)

            tableData.value = convertWithoutRecursion(tableCollection, root.value, curData.value, yoyData.value, preData.value, refData.value, 0);

            tableLoading.value = false
        }
    },
)
</script>
