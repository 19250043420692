/**
 * @file 数据标签层
 */

import Konva from 'konva';
import { sizeTool } from './Tools';

export default function draw(stage) {
    // 此处引入画布dom，用来控制标签hover时的鼠标样式
    let container = stage.container();
    const { size } = sizeTool(stage.width(), stage.height());

    let layer = new Konva.Layer();

    let state = {};

    const labelFontSize = size(15);

    function onLabelMouseEnter(e) {
        container.style.cursor = 'pointer';
        let key = e.target.name();
        if (!state[key]) {
            return;
        }
        e.target.textDecoration('underline');
    }

    function update() {
        for (let key in state) {
            let v = state[key];
            if (!components[key]) {
                continue;
            }
            let labelText = components[key].getText();
            if (v.value.toString() !== labelText.text()) {
                labelText.text(v.value);
            }
        }
    }

    function createLabelInstance(
        x,
        y,
        transparent = true,
        fontSize = labelFontSize,
    ) {
        let label = new Konva.Label({
            x: size(x),
            y: size(y),
        });
        if (!transparent) {
            label.add(
                new Konva.Tag({
                    fill: '#fff',
                })
            );
        }
        let text = new Konva.Text({
            x: 0,
            y: 0,
            text: '',
            fontSize,
            fill: '#01579B',
        });
        label.add(text);

        text.on('mouseenter', onLabelMouseEnter);
        text.on('mouseout', e => {
            container.style.cursor = 'default';
            e.target.textDecoration('');
        });
        text.on('click', () => {
            labelClickCallback && labelClickCallback(text.name());
        });
        return label;
    }

    let components = {
        avg_total: createLabelInstance(102,38),
        outnet: createLabelInstance(342,38),
        traffic_total: createLabelInstance(607,38),
        exchange_total: createLabelInstance(840,38),
        dsp_total: createLabelInstance(1022,38),
        traffic_common_total: createLabelInstance(1315,38),
        dsp_common_total: createLabelInstance(1528,38),
        cdn: createLabelInstance(1720,38),
        traffic_bid_feed_back_total: createLabelInstance(130,244),
        traffic_bid_feed_back_adx_lb: createLabelInstance(187,153),
        traffic_bid_feed_back_sdk_lb: createLabelInstance(219,192),
        traffic_bid_feed_back_adx: createLabelInstance(162,279),
        traffic_bid_feed_back_sdk: createLabelInstance(162,297),
        traffic_ssp_gather_total: createLabelInstance(838,280),
        traffic_ssp_gather_compute: createLabelInstance(870,308),
        traffic_ssp_gather_net: createLabelInstance(870,330),
        traffic_ssp_gather_other: createLabelInstance(870,351),
        traffic_ssp_gather_hb_cache: createLabelInstance(983,308),
        traffic_ssp_hb_lb: createLabelInstance(1148,174),
        traffic_ssp_hb_cdn: createLabelInstance(1152,200),
        traffic_ssp_hb_total: createLabelInstance(1102,262),
        traffic_ssp_hb_compute: createLabelInstance(1102,299),
        traffic_ssp_hb_az: createLabelInstance(1102,318),
        traffic_ssp_hb_other: createLabelInstance(1102,337),
        traffic_ssp_wf_lb: createLabelInstance(1272,181),
        traffic_ssp_wf_total: createLabelInstance(1219,262),
        traffic_ssp_wf_compute: createLabelInstance(1219,299),
        traffic_ssp_wf_az: createLabelInstance(1219,318),
        traffic_ssp_wf_other: createLabelInstance(1219,337),
        merger_total: createLabelInstance(503,840),
        merger_high_value_total: createLabelInstance(445,880),
        merger_high_value_adx_total: createLabelInstance(480,912),
        merger_high_value_adx_compute: createLabelInstance(480,931),
        merger_high_value_adx_other: createLabelInstance(480,947),
        merger_high_value_sdk_total: createLabelInstance(480,975),
        merger_high_value_sdk_compute: createLabelInstance(480,992),
        merger_high_value_sdk_other: createLabelInstance(480,1008),
        merger_low_value_total: createLabelInstance(559,880),
        merger_low_value_adx_total: createLabelInstance(595,912),
        merger_low_value_adx_compute: createLabelInstance(595,930),
        merger_low_value_adx_other: createLabelInstance(595,947),
        merger_low_value_sdk_total: createLabelInstance(595,975),
        merger_low_value_sdk_compute: createLabelInstance(595,992),
        merger_low_value_sdk_other: createLabelInstance(595,1008),
        rtdsd_total: createLabelInstance(676,855),
        rtdsd_adx_total: createLabelInstance(715,912),
        rtdsd_adx_compute: createLabelInstance(715,929),
        rtdsp_adx_other: createLabelInstance(715,945),
        rtdsd_sdk_total: createLabelInstance(715,975),
        rtdsp_sdk_compute: createLabelInstance(715,994),
        rtdsp_sdk_other: createLabelInstance(715,1010),
        juno_total: createLabelInstance(918,813),
        juno_high_value_total: createLabelInstance(880,849),
        juno_high_value_adx_total: createLabelInstance(915,876),
        juno_high_value_adx_compute: createLabelInstance(915,895),
        juno_high_value_adx_az: createLabelInstance(915,912),
        juno_high_value_adx_other: createLabelInstance(915,928),
        juno_high_value_sdk_total: createLabelInstance(915,956),
        juno_high_value_sdk_compute: createLabelInstance(915,976),
        juno_high_value_sdk_az: createLabelInstance(915,993),
        juno_high_value_sdk_other: createLabelInstance(915,1009),
        juno_low_value_total: createLabelInstance(1003,849),
        juno_low_value_adx_total: createLabelInstance(1040,876),
        juno_low_value_adx_compute: createLabelInstance(1040,895),
        juno_low_value_adx_az: createLabelInstance(1040,912),
        juno_low_value_adx_other: createLabelInstance(1040,928),
        juno_low_value_sdk_total: createLabelInstance(1040,956),
        juno_low_value_sdk_compute: createLabelInstance(1040,976),
        juno_low_value_sdk_az: createLabelInstance(1040,993),
        juno_low_value_sdk_other: createLabelInstance(1040,1009),
        juno_rt_total: createLabelInstance(1119,849),
        juno_rt_adx_total: createLabelInstance(1156,876),
        juno_rt_adx_compute: createLabelInstance(1156,896),
        juno_rt_adx_other: createLabelInstance(1156,913),
        juno_rt_sdk_total: createLabelInstance(1156,956),
        juno_rt_sdk_compute: createLabelInstance(1156,977),
        juno_rt_sdk_other: createLabelInstance(1156,993),
        own_dsp_ranker_total: createLabelInstance(612,1048),
        own_dsp_ranker_high_value_total: createLabelInstance(612,1067),
        own_dsp_ranker_high_value_adx_total: createLabelInstance(612,1089),
        own_dsp_ranker_high_value_adx_compute: createLabelInstance(612,1108),
        own_dsp_ranker_high_value_adx_az: createLabelInstance(612,1123),
        own_dsp_ranker_high_value_adx_region: createLabelInstance(612,1139),
        own_dsp_ranker_high_value_adx_other: createLabelInstance(612,1155),
        own_dsp_ranker_high_value_sdk_total: createLabelInstance(771,1089),
        own_dsp_ranker_high_value_sdk_compute: createLabelInstance(771,1108),
        own_dsp_ranker_high_value_sdk_az: createLabelInstance(771,1123),
        own_dsp_ranker_high_value_sdk_region: createLabelInstance(771,1139),
        own_dsp_ranker_high_value_sdk_other: createLabelInstance(771,1155),
        own_dsp_ranker_low_value_total: createLabelInstance(939,1067),
        own_dsp_ranker_low_value_adx_total: createLabelInstance(939,1089),
        own_dsp_ranker_low_value_adx_compute: createLabelInstance(939,1108),
        own_dsp_ranker_low_value_adx_az: createLabelInstance(939,1123),
        own_dsp_ranker_low_value_adx_region: createLabelInstance(939,1139),
        own_dsp_ranker_low_value_adx_other: createLabelInstance(939,1155),
        own_dsp_ranker_low_value_sdk_total: createLabelInstance(1090,1089),
        own_dsp_ranker_low_value_sdk_compute: createLabelInstance(1090,1108),
        own_dsp_ranker_low_value_sdk_az: createLabelInstance(1090,1123),
        own_dsp_ranker_low_value_sdk_region: createLabelInstance(1090,1139),
        own_dsp_ranker_low_value_sdk_other: createLabelInstance(1090,1155),
        exchange_madx_lb: createLabelInstance(425,193),
        exchange_madx_gather_total: createLabelInstance(257,280),
        exchange_madx_gather_compute: createLabelInstance(288,314),
        exchange_madx_gather_net: createLabelInstance(288,333),
        exchange_madx_gather_other: createLabelInstance(288,351),
        exchange_madx_dsp_gather_total: createLabelInstance(257,432),
        exchange_madx_dsp_gather_compute: createLabelInstance(288,460),
        exchange_madx_dsp_gather_net: createLabelInstance(288,478),
        exchange_madx_dsp_gather_other: createLabelInstance(288,497),
        exchange_madx_total: createLabelInstance(376,280),
        exchange_madx_adx_total: createLabelInstance(409,314),
        exchange_madx_adx_compute: createLabelInstance(409,340),
        exchange_madx_adx_other: createLabelInstance(409,357),
        exchange_madx_sdk_total: createLabelInstance(409,419),
        exchange_madx_sdk_compute: createLabelInstance(409,442),
        exchange_madx_sdk_other: createLabelInstance(409,459),
        exchange_madx_net_total: createLabelInstance(232,673),
        exchange_madx_net_adx: createLabelInstance(282,688),
        exchange_madx_net_sdk: createLabelInstance(282,704),
        exchange_pre_bidder_total: createLabelInstance(980,465),
        exchange_pre_bidder_adx_total: createLabelInstance(945,485),
        exchange_pre_bidder_adx_compute: createLabelInstance(945,513),
        exchange_pre_bidder_adx_cache: createLabelInstance(945,528),
        exchange_pre_bidder_adx_az: createLabelInstance(945,544),
        exchange_pre_bidder_adx_other: createLabelInstance(945,561),
        exchange_pre_bidder_sdk_total: createLabelInstance(945,593),
        exchange_pre_bidder_sdk_compute: createLabelInstance(945,622),
        exchange_pre_bidder_sdk_cache: createLabelInstance(945,638),
        exchange_pre_bidder_sdk_az: createLabelInstance(945,654),
        exchange_pre_bidder_sdk_other: createLabelInstance(945,670),
        exchange_final_bidder_high_value_total: createLabelInstance(1115,485),
        exchange_final_bidder_high_value_adx_total: createLabelInstance(1115,507),
        exchange_final_bidder_high_value_adx_compute: createLabelInstance(1115,527),
        exchange_final_bidder_high_value_adx_az: createLabelInstance(1115,543),
        exchange_final_bidder_high_value_adx_other: createLabelInstance(1115,560),
        exchange_final_bidder_high_value_sdk_total: createLabelInstance(1115,604),
        exchange_final_bidder_high_value_sdk_compute: createLabelInstance(1115,630),
        exchange_final_bidder_high_value_sdk_az: createLabelInstance(1115,646),
        exchange_final_bidder_high_value_sdk_other: createLabelInstance(1115,662),
        exchange_final_bidder_low_value_total: createLabelInstance(1248,486),
        exchange_final_bidder_low_value_adx_total: createLabelInstance(1248,507),
        exchange_final_bidder_low_value_adx_compute: createLabelInstance(1248,527),
        exchange_final_bidder_low_value_adx_az: createLabelInstance(1248,543),
        exchange_final_bidder_low_value_adx_other: createLabelInstance(1248,560),
        exchange_final_bidder_low_value_sdk_total: createLabelInstance(1248,604),
        exchange_final_bidder_low_value_sdk_compute: createLabelInstance(1248,630),
        exchange_final_bidder_low_value_sdk_az: createLabelInstance(1248,646),
        exchange_final_bidder_low_value_sdk_other: createLabelInstance(1248,662),
        exchange_final_bidder_total: createLabelInstance(1145,465),
        exchange_final_bidder_old_total: createLabelInstance(1380,486),
        exchange_final_bidder_old_adx_total: createLabelInstance(1380,508),
        exchange_final_bidder_old_adx_compute: createLabelInstance(1380,527),
        exchange_final_bidder_old_adx_az: createLabelInstance(1380,543),
        exchange_final_bidder_old_adx_other: createLabelInstance(1380,560),
        exchange_final_bidder_old_sdk_total: createLabelInstance(1380,604),
        exchange_final_bidder_old_sdk_compute: createLabelInstance(1380,630),
        exchange_final_bidder_old_sdk_az: createLabelInstance(1380,646),
        exchange_final_bidder_old_sdk_other: createLabelInstance(1380,662),
        rrid_total: createLabelInstance(1812,225),
        rrid_cache: createLabelInstance(1812,251),
        rrid_other: createLabelInstance(1812,267),
        smart_bid_total_cache: createLabelInstance(1987,251),
        oneid_total: createLabelInstance(1820,297),
        oneid_adx_total: createLabelInstance(1812,316),
        oneid_adx_compute: createLabelInstance(1812,339),
        oneid_adx_cache: createLabelInstance(1812,355),
        oneid_adx_other: createLabelInstance(1812,371),
        oneid_sdk_total: createLabelInstance(1812,403),
        oneid_sdk_compute: createLabelInstance(1812,421),
        oneid_sdk_cache: createLabelInstance(1812,437),
        oneid_sdk_other: createLabelInstance(1812,453),
        geo_total: createLabelInstance(1987,297),
        geo_adx_total_cache: createLabelInstance(1987,341),
        geo_sdk_total: createLabelInstance(1987,392),
        geo_sdk_compute: createLabelInstance(1987,414),
        geo_sdk_cache: createLabelInstance(1987,430),
        geo_sdk_other: createLabelInstance(1987,445),
        treasure_box_total: createLabelInstance(2230,410),
        treasure_box_adx_cache: createLabelInstance(2170,452),
        treasure_box_sdk_cache: createLabelInstance(2294,452),
        pre_filter_cache_total: createLabelInstance(2250,325),
        pre_filter_cache_adx: createLabelInstance(2170,367),
        pre_filter_cache_sdk: createLabelInstance(2294,367),
        uss_total: createLabelInstance(2050,509),
        uss_server_total: createLabelInstance(1820,531),
        uss_server_adx_total: createLabelInstance(1812,551),
        uss_server_adx_compute: createLabelInstance(1812,569),
        uss_server_adx_az: createLabelInstance(1812,586),
        uss_server_adx_other: createLabelInstance(1812,602),
        uss_server_sdk_total: createLabelInstance(1812,628),
        uss_server_sdk_compute: createLabelInstance(1812,647),
        uss_server_sdk_az: createLabelInstance(1812,663),
        uss_server_sdk_other: createLabelInstance(1812,679),
        uss_install_total: createLabelInstance(2070,531),
        uss_install_cache: createLabelInstance(1995,553),
        uss_install_az: createLabelInstance(1995,569),
        uss_install_other: createLabelInstance(1995,586),
        uss_redirect_total: createLabelInstance(1995,626),
        uss_redirect_cache: createLabelInstance(1995,647),
        uss_redirect_az: createLabelInstance(1995,664),
        uss_redirect_other: createLabelInstance(1995,679),
        uss_frequency_total: createLabelInstance(2220,531),
        uss_frequency_cache: createLabelInstance(2195,554),
        uss_frequency_az: createLabelInstance(2195,569),
        uss_frequency_other: createLabelInstance(2195,586),
        USS_Lambda: createLabelInstance(2220,625),
        ups_total: createLabelInstance(1530,812),
        ups_adx_total: createLabelInstance(1530,836),
        ups_adx_cache: createLabelInstance(1530,862),
        ups_adx_az: createLabelInstance(1530,878),
        ups_adx_other: createLabelInstance(1530,894),
        ups_sdk_total: createLabelInstance(1530,937),
        ups_sdk_cache: createLabelInstance(1530,964),
        ups_sdk_az: createLabelInstance(1530,979),
        ups_sdk_other: createLabelInstance(1530,995),
        dmp_total: createLabelInstance(1690,813),
        dmp_adx_total: createLabelInstance(1690,845),
        dmp_sdk_total: createLabelInstance(1690,879),
        dmp_sdk_cache: createLabelInstance(1690,906),
        dmp_sdk_az: createLabelInstance(1690,922),
        dmp_sdk_other: createLabelInstance(1690,938),
        dmp_adx_and_sdk_total: createLabelInstance(1645,995),
        dmp_adx_and_sdk_cache: createLabelInstance(1690,1019),
        dmp_adx_and_sdk_az: createLabelInstance(1690,1035),
        dmp_adx_and_sdk_other: createLabelInstance(1690,1051),
        nps_total: createLabelInstance(1845,810),
        nps_high_value_total: createLabelInstance(1853,833),
        nps_high_value_adx_total: createLabelInstance(1845,854),
        nps_high_value_adx_compute: createLabelInstance(1845,877),
        nps_high_value_adx_other: createLabelInstance(1845,893),
        nps_high_value_sdk_total: createLabelInstance(1845,922),
        nps_high_value_sdk_compute: createLabelInstance(1845,947),
        nps_high_value_sdk_other: createLabelInstance(1845,963),
        nps_low_value_total: createLabelInstance(2000,833),
        nps_low_value_adx_total: createLabelInstance(1990,854),
        nps_low_value_adx_compute: createLabelInstance(1990,878),
        nps_low_value_adx_other: createLabelInstance(1990,893),
        nps_low_value_sdk_total: createLabelInstance(1990,922),
        nps_low_value_sdk_compute: createLabelInstance(1990,946),
        nps_low_value_sdk_other: createLabelInstance(1990,963),
        nps_common_value_total: createLabelInstance(1845,1002),
        nps_common_value_adx_total: createLabelInstance(1845,1022),
        nps_common_value_adx_compute: createLabelInstance(1845,1043),
        nps_common_value_adx_other: createLabelInstance(1845,1060),
        nps_common_value_sdk_total: createLabelInstance(1845,1089),
        nps_common_value_sdk_compute: createLabelInstance(1845,1114),
        nps_common_value_sdk_other: createLabelInstance(1845,1130),
        nps_more_offer_total: createLabelInstance(1951,1018),
        nps_more_offer_compute: createLabelInstance(1985,1043),
        nps_more_offer_other: createLabelInstance(1985,1060),
        ms_total: createLabelInstance(2160,809),
        ms_high_value_total: createLabelInstance(2175,835),
        ms_high_value_adx_total: createLabelInstance(2160,859),
        ms_high_value_adx_compute: createLabelInstance(2160,887),
        ms_high_value_adx_other: createLabelInstance(2160,903),
        ms_high_value_sdk_total: createLabelInstance(2290,859),
        ms_high_value_sdk_compute: createLabelInstance(2290,887),
        ms_high_value_sdk_other: createLabelInstance(2290,903),
        ms_low_value_total: createLabelInstance(2175,944),
        ms_low_value_adx_total: createLabelInstance(2160,969),
        ms_low_value_adx_compute: createLabelInstance(2160,994),
        ms_low_value_adx_other: createLabelInstance(2160,1010),
        ms_low_value_sdk_total: createLabelInstance(2290,969),
        ms_low_value_sdk_compute: createLabelInstance(2290,994),
        ms_low_value_sdk_other: createLabelInstance(2290,1010),
        ms_more_offer_total: createLabelInstance(2205,1052),
        ms_more_offer_compute: createLabelInstance(2160,1074),
        ms_more_offer_other: createLabelInstance(2160,1090),
        exchange_setting_lb: createLabelInstance(1430,170),
        exchange_setting_total: createLabelInstance(1319,244),
        exchange_setting_compute: createLabelInstance(1380,278),
        exchange_setting_region: createLabelInstance(1380,298),
        exchange_setting_az: createLabelInstance(1380,316),
        exchange_setting_other: createLabelInstance(1380,334),
        exchange_analytics_lb: createLabelInstance(1539,170),
        exchange_analytics_total: createLabelInstance(1458,244),
        exchange_analytics_compute: createLabelInstance(1515,278),
        exchange_analytics_log: createLabelInstance(1515,298),
        exchange_analytics_other: createLabelInstance(1515,317),
        exchange_monetization_lb: createLabelInstance(1675,169),
        exchange_monetization_total: createLabelInstance(1595,244),
        exchange_monetization_compute: createLabelInstance(1650,278),
        exchange_monetization_log: createLabelInstance(1650,298),
        exchange_monetization_other: createLabelInstance(1650,317),
        mongodb_traffic_total: createLabelInstance(2265,224),
        mongodb_traffic_lb: createLabelInstance(2170,247),
        mongodb_traffic_compute: createLabelInstance(2170,263),
        mongodb_traffic_other: createLabelInstance(2170,280),
        mongodb_dsp_total: createLabelInstance(1488,1062),
        mongodb_dsp_lb: createLabelInstance(1523,1078),
        mongodb_dsp_compute: createLabelInstance(1523,1094),
        mongodb_dsp_az: createLabelInstance(1523,1110),
        mongodb_dsp_other: createLabelInstance(1523,1126),
        frontier_gather_total: createLabelInstance(545,281),
        frontier_gather_compute: createLabelInstance(575,310),
        frontier_gather_netout: createLabelInstance(575,331),
        frontier_gather_other: createLabelInstance(575,353),
        frontier_total: createLabelInstance(663,281),
        frontier_adx_total: createLabelInstance(699,314),
        frontier_adx_compute: createLabelInstance(699,340),
        frontier_adx_other: createLabelInstance(699,357),
        frontier_sdk_total: createLabelInstance(699,418),
        frontier_sdk_compute: createLabelInstance(699,442),
        frontier_sdk_other: createLabelInstance(699,460),
        frontier_adx_lb: createLabelInstance(712,200),
        frontier_sdk_lb: createLabelInstance(772,203),
        frontier_netout: createLabelInstance(372,736),
        frontier_adx_netout: createLabelInstance(425,751),
        frontier_sdk_netout: createLabelInstance(425,767),
        madx_frontier_netout_total: createLabelInstance(310,845),
        madx_frontier_adx_netout: createLabelInstance(310,864),
        madx_frontier_sdk_netout: createLabelInstance(310,883),
        ruf_sdk_total: createLabelInstance(1545,1206),
        ruf_sdk_cache: createLabelInstance(1545,1237),
        ruf_sdk_az: createLabelInstance(1545,1253),
        ruf_sdk_region: createLabelInstance(1545,1269),
        ruf_sdk_other: createLabelInstance(1545,1285),
        duf_total: createLabelInstance(1722,1184),
        duf_adx_sdk_total: createLabelInstance(1750,1206),
        duf_adx_sdk_cache: createLabelInstance(1735,1237),
        duf_sdk_sdk_az: createLabelInstance(1735,1253),
        duf_adx_sdk_region: createLabelInstance(1735,1269),
        duf_adx_sdk_other: createLabelInstance(1735,1285),
        duf_sdk_total: createLabelInstance(1900,1206),
        duf_sdk_cache: createLabelInstance(1900,1237),
        duf_sdk_az: createLabelInstance(1900,1253),
        duf_sdk_region: createLabelInstance(1900,1269),
        duf_sdk_other: createLabelInstance(1900,1285),
        first_req_recover_total: createLabelInstance(2407,239),
        first_req_recover_adx: createLabelInstance(2444,281),
        first_req_recover_sdk: createLabelInstance(2444,334),
    };

    for (let key in components) {
        let label = components[key];
        label.getText().name(key);
        layer.add(label);
    }

    stage.add(layer);

    let labelClickCallback = null;

    return {
        layer,
        setData(data) {
            state = data;
            // for (let key in components) {
            //     components[key].getText().text('');
            // }
            update();
        },
        onLabelClick(listener) {
            labelClickCallback = listener;
        },
    };
}
