const collection = {
    avg_total: 554,
    dsp_common_total: 629,
    dsp_total: 628,
    exchange_total: 627,
    traffic_total: 626,
    outnet: 621,
    cdn: 752,
    traffic_common_total: 630,
    traffic_bid_feed_back_total: 285,
    traffic_bid_feed_back_adx_lb: 287,
    traffic_bid_feed_back_sdk_lb: 286,
    traffic_bid_feed_back_adx: 284,
    traffic_bid_feed_back_sdk: 283,
    traffic_ssp_gather_total: 282,
    traffic_ssp_gather_compute: 279,
    traffic_ssp_gather_net: 280,
    traffic_ssp_gather_other: 281,
    traffic_ssp_gather_hb_cache: 358,
    traffic_ssp_hb_lb: 640,
    traffic_ssp_hb_cdn: 650,
    traffic_ssp_hb_total: 644,
    traffic_ssp_hb_compute: 641,
    traffic_ssp_hb_az: 642,
    traffic_ssp_hb_other: 643,
    traffic_ssp_wf_lb: 646,
    traffic_ssp_wf_total: 645,
    traffic_ssp_wf_compute: 647,
    traffic_ssp_wf_az: 648,
    traffic_ssp_wf_other: 649,
    exchange_madx_lb: 296,
    exchange_madx_gather_total: 278,
    exchange_madx_gather_compute: 275,
    exchange_madx_gather_net: 276,
    exchange_madx_gather_other: 277,
    exchange_madx_dsp_gather_total: 274,
    exchange_madx_dsp_gather_compute: 270,
    exchange_madx_dsp_gather_net: 272,
    exchange_madx_dsp_gather_other: 273,
    exchange_madx_total: 303,
    exchange_madx_adx_total: 295,
    exchange_madx_adx_compute: 293,
    exchange_madx_adx_other: 294,
    exchange_madx_sdk_total: 302,
    exchange_madx_sdk_compute: 298,
    exchange_madx_sdk_other: 299,
    exchange_madx_net_total: 504,
    exchange_madx_net_adx: 297,
    exchange_madx_net_sdk: 301,
    exchange_pre_bidder_total: 307,
    exchange_pre_bidder_adx_total: 394,
    exchange_pre_bidder_adx_compute: 393,
    exchange_pre_bidder_adx_cache: 639,
    exchange_pre_bidder_adx_az: 392,
    exchange_pre_bidder_adx_other: 391,
    exchange_pre_bidder_sdk_total: 398,
    exchange_pre_bidder_sdk_compute: 397,
    exchange_pre_bidder_sdk_cache: 638,
    exchange_pre_bidder_sdk_az: 396,
    exchange_pre_bidder_sdk_other: 395,
    exchange_final_bidder_high_value_total: 742,
    exchange_final_bidder_high_value_adx_total: 737,
    exchange_final_bidder_high_value_adx_compute: 736,
    exchange_final_bidder_high_value_adx_az: 735,
    exchange_final_bidder_high_value_adx_other: 734,
    exchange_final_bidder_high_value_sdk_total: 741,
    exchange_final_bidder_high_value_sdk_compute: 740,
    exchange_final_bidder_high_value_sdk_az: 739,
    exchange_final_bidder_high_value_sdk_other: 738,
    exchange_final_bidder_low_value_total: 751,
    exchange_final_bidder_low_value_adx_total: 746,
    exchange_final_bidder_low_value_adx_compute: 745,
    exchange_final_bidder_low_value_adx_az: 744,
    exchange_final_bidder_low_value_adx_other: 743,
    exchange_final_bidder_low_value_sdk_total: 750,
    exchange_final_bidder_low_value_sdk_compute: 749,
    exchange_final_bidder_low_value_sdk_az: 748,
    exchange_final_bidder_low_value_sdk_other: 747,
    exchange_final_bidder_total: 406,
    exchange_final_bidder_old_total: 772,
    exchange_final_bidder_old_adx_total: 401,
    exchange_final_bidder_old_adx_compute: 400,
    exchange_final_bidder_old_adx_az: 399,
    exchange_final_bidder_old_adx_other: 310,
    exchange_final_bidder_old_sdk_total: 405,
    exchange_final_bidder_old_sdk_compute: 404,
    exchange_final_bidder_old_sdk_az: 403,
    exchange_final_bidder_old_sdk_other: 402,
    exchange_setting_lb: 603,
    exchange_setting_total: 602,
    exchange_setting_compute: 601,
    exchange_setting_region: 600,
    exchange_setting_az: 599,
    exchange_setting_other: 598,
    exchange_analytics_lb: 608,
    exchange_analytics_total: 607,
    exchange_analytics_compute: 605,
    exchange_analytics_log: 606,
    exchange_analytics_other: 604,
    exchange_monetization_lb: 613,
    exchange_monetization_total: 612,
    exchange_monetization_compute: 610,
    exchange_monetization_log: 611,
    exchange_monetization_other: 609,
    mongodb_traffic_total: 596,
    mongodb_traffic_lb: 635,
    mongodb_traffic_compute: 636,
    mongodb_traffic_other: 637,
    mongodb_dsp_total: 597,
    mongodb_dsp_lb: 631,
    mongodb_dsp_compute: 632,
    mongodb_dsp_az: 633,
    mongodb_dsp_other: 634,
    merger_total: 676,
    merger_high_value_total: 675,
    merger_high_value_adx_total: 671,
    merger_high_value_adx_compute: 670,
    merger_high_value_adx_other: 669,
    merger_high_value_sdk_total: 674,
    merger_high_value_sdk_compute: 673,
    merger_high_value_sdk_other: 672,
    merger_low_value_total: 668,
    merger_low_value_adx_total: 664,
    merger_low_value_adx_compute: 663,
    merger_low_value_adx_other: 662,
    merger_low_value_sdk_total: 667,
    merger_low_value_sdk_compute: 666,
    merger_low_value_sdk_other: 665,
    rtdsd_total: 620,
    rtdsd_adx_total: 616,
    rtdsd_adx_compute: 615,
    rtdsp_adx_other: 614,
    rtdsd_sdk_total: 619,
    rtdsp_sdk_compute: 618,
    rtdsp_sdk_other: 617,
    juno_total: 693,
    juno_high_value_total: 695,
    juno_high_value_adx_total: 688,
    juno_high_value_adx_compute: 687,
    juno_high_value_adx_az: 686,
    juno_high_value_adx_other: 685,
    juno_high_value_sdk_total: 692,
    juno_high_value_sdk_compute: 691,
    juno_high_value_sdk_az: 690,
    juno_high_value_sdk_other: 689,
    juno_low_value_total: 694,
    juno_low_value_adx_total: 680,
    juno_low_value_adx_compute: 679,
    juno_low_value_adx_az: 678,
    juno_low_value_adx_other: 677,
    juno_low_value_sdk_total: 684,
    juno_low_value_sdk_compute: 683,
    juno_low_value_sdk_az: 682,
    juno_low_value_sdk_other: 681,
    juno_rt_total: 422,
    juno_rt_adx_total: 417,
    juno_rt_adx_compute: 416,
    juno_rt_adx_other: 414,
    juno_rt_sdk_total: 421,
    juno_rt_sdk_compute: 420,
    juno_rt_sdk_other: 418,
    own_dsp_ranker_total: 370,
    own_dsp_ranker_high_value_total: 381,
    own_dsp_ranker_high_value_adx_total: 375,
    own_dsp_ranker_high_value_adx_compute: 371,
    own_dsp_ranker_high_value_adx_az: 372,
    own_dsp_ranker_high_value_adx_region: 373,
    own_dsp_ranker_high_value_adx_other: 374,
    own_dsp_ranker_high_value_sdk_total: 380,
    own_dsp_ranker_high_value_sdk_compute: 376,
    own_dsp_ranker_high_value_sdk_az: 377,
    own_dsp_ranker_high_value_sdk_region: 378,
    own_dsp_ranker_high_value_sdk_other: 379,
    own_dsp_ranker_low_value_total: 425,
    own_dsp_ranker_low_value_adx_total: 386,
    own_dsp_ranker_low_value_adx_compute: 385,
    own_dsp_ranker_low_value_adx_az: 383,
    own_dsp_ranker_low_value_adx_region: 384,
    own_dsp_ranker_low_value_adx_other: 426,
    own_dsp_ranker_low_value_sdk_total: 427,
    own_dsp_ranker_low_value_sdk_compute: 387,
    own_dsp_ranker_low_value_sdk_az: 423,
    own_dsp_ranker_low_value_sdk_region: 424,
    own_dsp_ranker_low_value_sdk_other: 389,
    rrid_total: 323,
    rrid_cache: 321,
    rrid_other: 322,
    smart_bid_total_cache: 335,
    oneid_total: 436,
    oneid_adx_total: 431,
    oneid_adx_compute: 430,
    oneid_adx_cache: 429,
    oneid_adx_other: 428,
    oneid_sdk_total: 435,
    oneid_sdk_compute: 434,
    oneid_sdk_cache: 433,
    oneid_sdk_other: 432,
    geo_total: 443,
    geo_adx_total_cache: 438,
    geo_sdk_total: 442,
    geo_sdk_compute: 441,
    geo_sdk_cache: 440,
    geo_sdk_other: 439,
    treasure_box_total: 445,
    treasure_box_adx_cache: 444,
    treasure_box_sdk_cache: 332,
    pre_filter_cache_total: 654,
    pre_filter_cache_adx: 653,
    pre_filter_cache_sdk: 652,
    uss_total: 469,
    uss_server_total: 453,
    uss_server_adx_total: 448,
    uss_server_adx_compute: 447,
    uss_server_adx_az: 446,
    uss_server_adx_other: 458,
    uss_server_sdk_total: 452,
    uss_server_sdk_compute: 451,
    uss_server_sdk_az: 450,
    uss_server_sdk_other: 449,
    uss_install_total: 456,
    uss_install_cache: 455,
    uss_install_az: 457,
    uss_install_other: 501,
    uss_redirect_total: 467,
    uss_redirect_cache: 466,
    uss_redirect_az: 465,
    uss_redirect_other: 464,
    uss_frequency_total: 462,
    uss_frequency_cache: 459,
    uss_frequency_az: 460,
    uss_frequency_other: 461,
    USS_Lambda: 468,
    ups_total: 478,
    ups_adx_total: 473,
    ups_adx_cache: 472,
    ups_adx_az: 471,
    ups_adx_other: 470,
    ups_sdk_total: 477,
    ups_sdk_cache: 476,
    ups_sdk_az: 475,
    ups_sdk_other: 474,
    dmp_total: 489,
    dmp_adx_total: 480,
    dmp_sdk_total: 484,
    dmp_sdk_cache: 483,
    dmp_sdk_az: 482,
    dmp_sdk_other: 481,
    dmp_adx_and_sdk_total: 488,
    dmp_adx_and_sdk_cache: 487,
    dmp_adx_and_sdk_az: 486,
    dmp_adx_and_sdk_other: 485,
    nps_total: 553,
    nps_high_value_total: 535,
    nps_high_value_adx_total: 531,
    nps_high_value_adx_compute: 530,
    nps_high_value_adx_other: 529,
    nps_high_value_sdk_total: 534,
    nps_high_value_sdk_compute: 533,
    nps_high_value_sdk_other: 532,
    nps_low_value_total: 542,
    nps_low_value_adx_total: 538,
    nps_low_value_adx_compute: 537,
    nps_low_value_adx_other: 536,
    nps_low_value_sdk_total: 541,
    nps_low_value_sdk_compute: 540,
    nps_low_value_sdk_other: 539,
    nps_common_value_total: 552,
    nps_common_value_adx_total: 548,
    nps_common_value_adx_compute: 547,
    nps_common_value_adx_other: 546,
    nps_common_value_sdk_total: 551,
    nps_common_value_sdk_compute: 550,
    nps_common_value_sdk_other: 549,
    nps_more_offer_total: 545,
    nps_more_offer_compute: 544,
    nps_more_offer_other: 543,
    ms_total: 528,
    ms_high_value_total: 527,
    ms_high_value_adx_total: 523,
    ms_high_value_adx_compute: 522,
    ms_high_value_adx_other: 521,
    ms_high_value_sdk_total: 526,
    ms_high_value_sdk_compute: 525,
    ms_high_value_sdk_other: 524,
    ms_low_value_total: 517,
    ms_low_value_adx_total: 513,
    ms_low_value_adx_compute: 512,
    ms_low_value_adx_other: 511,
    ms_low_value_sdk_total: 516,
    ms_low_value_sdk_compute: 515,
    ms_low_value_sdk_other: 514,
    ms_more_offer_total: 520,
    ms_more_offer_compute: 519,
    ms_more_offer_other: 518,
    frontier_gather_total: 729,
    frontier_gather_compute: 728,
    frontier_gather_netout: 727,
    frontier_gather_other: 726,
    frontier_total: 725,
    frontier_adx_total: 719,
    frontier_adx_compute: 716,
    frontier_adx_other: 715,
    frontier_sdk_total: 724,
    frontier_sdk_compute: 721,
    frontier_sdk_other: 720,
    frontier_adx_lb: 717,
    frontier_sdk_lb: 722,
    frontier_netout: 730,
    frontier_adx_netout: 718,
    frontier_sdk_netout: 723,
    madx_frontier_netout_total: 771,
    madx_frontier_adx_netout: 769,
    madx_frontier_sdk_netout: 770,
    ruf_sdk_total: 756,
    ruf_sdk_cache: 755,
    ruf_sdk_az: 754,
    ruf_sdk_region: 757,
    ruf_sdk_other: 753,
    duf_total: 768,
    duf_adx_sdk_total: 767,
    duf_adx_sdk_cache: 766,
    duf_sdk_sdk_az: 764,
    duf_adx_sdk_region: 765,
    duf_adx_sdk_other: 763,
    duf_sdk_total: 762,
    duf_sdk_cache: 761,
    duf_sdk_az: 759,
    duf_sdk_region: 760,
    duf_sdk_other: 758,
    first_req_recover_total: 787,
    first_req_recover_adx: 785,
    first_req_recover_sdk: 786,
}

export default collection